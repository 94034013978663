import React from "react";
import { BannerAd, BannerAdSize, TestIds } from "react-native-google-mobile-ads";
import { Subheading } from "react-native-paper";
import styles from "../../css/myCss";
import { Platform, View } from "react-native";

/* gia na emfanisw diafhmiseis prepei na mhn to trexw me 
                  yarn expo start
              alla me 
                  npx expo run:android
          
          <BannerAd unitId={TestIds.BANNER} 
                  size={BannerAdSize.ANCHORED_ADAPTIVE_BANNER}
                  requestOptions={{
                  requestNonPersonalizedAdsOnly: true,}} 
          />*/

/* gia na trexw me yarn expo start:
        <Subheading style={styles.adBanner}>Διαφημίσεις ανενεργές</Subheading>
*/

let MyAdBanner;
if (Platform.OS === 'web') {  //WEB
        MyAdBanner = () => <View></View>;
} else {                      //REACT NATIVE
        MyAdBanner = () => <Subheading style={styles.adBanner}>Διαφημίσεις ανενεργές</Subheading>;
        /*MyAdBanner = () => <BannerAd 
                                unitId={TestIds.BANNER} 
                                size={BannerAdSize.ANCHORED_ADAPTIVE_BANNER} 
                                requestOptions={{ requestNonPersonalizedAdsOnly: true,}} 
                           />;*/
}

const AdBanner = () => {
     return (
        <MyAdBanner/>
     );
    };
   
export default AdBanner; 