import React, { useState, useEffect, useRef } from 'react';
import { TextInput, Button, Snackbar, Appbar } from 'react-native-paper';
import { Alert, Keyboard, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import GUIManager from '../../managers/GUIManager';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { useNavigation } from '@react-navigation/native';
import PlayerMenuOptions from './PlayerMenuOptions';
import styles from '../../css/myCss';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import AdBanner from '../generic/AdBanner';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import RightColumnWeb from '../generic/RightColumnWeb';
import LeftColumnWeb from '../generic/LeftColumnWeb';

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

const PlayerContactUsView = () => {
  const navigation = useNavigation();

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("PlayerContactUsView: I'm ready!");
    (async () => {
      const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
    })();
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);

  const [comments, setComments] = useState('');

  const [snackBarVisible, setSnackBarVisible] = useState(false);
  useEffect(() => {
    if(snackBarVisible == false) {
      setSnackBarText("");
    }
  }, [snackBarVisible]);
  const onDismissSnackBar = () => setSnackBarVisible(false);

  const [snackBarText, setSnackBarText] = useState<string>('');
  useEffect(() => {
    if(snackBarText != '') {
      setIsLoading(false);
      setSnackBarVisible(true);
      setComments(""); //init
    }
  }, [snackBarText]);

  async function sendCommentsAsync() 
  {
    if(comments === '') {
      if(MyAlertReactNative != null)
        alert(i18n.t('completeComments'));
      else if(MyAlertForWeb != null) {
        setAlertForWebText(i18n.t('completeComments'));
        setAlertForWebVisible(true);
      }
    }
    else {
      setIsLoading(true);       
      let webServiceResponseStr;
      if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO)
        webServiceResponseStr = await Studio_RestManager.getInstance().addComment(comments);
      else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS)
        webServiceResponseStr = await Tennis_RestManager.getInstance().addComment(comments);
      setSnackBarText(i18n.t('commentsSubmittedSuccessfully'));
    }
  }

  // Create a ref for the TextInput components
  const commentsInputRef = useRef(null);

  return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.columnContainer_threeColumnsAndBottomNavigation}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>
              <Appbar.Header>
                <Appbar.Content title={i18n.t('contactUs')} />
              </Appbar.Header>
              <ScrollView style={styles.scrollView}>
                <Spinner
                  visible={isLoading}
                  textContent={i18n.t('loading')}
                  textStyle={styles.spinnerTextStyle}
                />
                <TouchableOpacity onPress={() => commentsInputRef.current.focus()}>
                  <TextInput
                    ref={commentsInputRef}
                    label={i18n.t('yourComments')}
                    value={comments}
                    onChangeText={tmpComments => setComments(tmpComments)}
                  />
                </TouchableOpacity>
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)} mode="contained" labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} onPress={() => sendCommentsAsync()}>{i18n.t('submitReport')}</Button>
              </ScrollView>
              <Snackbar
                style={styles.snackbarUpper}
                visible={snackBarVisible}
                onDismiss={onDismissSnackBar}>
                {snackBarText}
              </Snackbar>
              {MyAlertForWeb!=null && 
                <MyAlertForWeb
                  show={alertForWebVisible}
                  showProgress={false}
                  title={i18n.t('caution')}
                  message={alertForWebText}
                  closeOnTouchOutside={false}
                  closeOnHardwareBackPress={false}
                  showConfirmButton={true}
                  confirmText="OK"
                  confirmButtonColor="#DD6B55"
                  onCancelPressed={() => {
                    setAlertForWebVisible(false);
                  }}
                  onConfirmPressed={() => {
                    setAlertForWebVisible(false);
                  }}  
                />
              }
              <AdBanner/>
            </View>
            <RightColumnWeb/>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    );
  };

export default PlayerContactUsView;